import Swal from 'sweetalert2';

export const Toasts = (icon, text, time = 3000) => {
    return Swal.fire({
        toast: true,
        position: 'top-right',
        icon: icon,
        title: text,
        customClass: {
            popup: 'colored-toast'
        },
        iconColor: '#ffffff',
        showConfirmButton: false,
        timer: time,
        timerProgressBar: true,
    })
}