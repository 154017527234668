<script>
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { passwordStrength } from 'check-password-strength'
import { register } from './api';
import { Toasts } from '../../../utils/toast-alerts';

export default {    
    data() {
        return {
            v$: useVuelidate(),
            payload: {
                email: '',
                name: '',
                password: '',
                whatsapp: '',
                term: false,
            },
            isFormSubmitted: false,
            linkTerms: 'https://ss-core-storage.nyc3.cdn.digitaloceanspaces.com/iris/supra-sorte-termos-de-uso.pdf',
            linkPrivacy: 'https://ss-core-storage.nyc3.cdn.digitaloceanspaces.com/iris/supra-sorte-politica-de-privacidade.pdf',
            showPassword: false,
            passwordStrengthColors: [
              'danger',
              'warning',
              'info',
              'success'
            ]
        }
    },

    methods: {
        async register() {
            this.isFormSubmitted = true;
            this.v$.$touch();
            
            if (!this.v$.payload.$invalid && this.v$.payload.term.$model) {

                try {
                  await register(this.payload);

                  Toasts('success', 'Cadastro criado com sucesso!')

                  this.isFormSubmitted = false;

                  window.setTimeout(() => {
                    this.$router.push({ name: 'home' });
                  }, 1500)
                } catch ({response}) {
                    const { errors } = response.data;

                    Object.keys(errors).forEach((key) => {                        
                        errors[key].forEach((message) => {
                            Toasts('error', message)
                        })
                    })

                    this.isFormSubmitted = false;
                } 
            }
        },
    },

    validations()  {
        return {
            payload: {
                email: {
                    required,
                    email,
                },
                name: {
                    required,
                    minLength: minLength(3),
                },
                password: {
                    required,
                    minLength: minLength(6),
                },
                whatsapp: {
                    required,
                    minLength: minLength(16),
                },
                term: {
                    required,
                },
            },
        }
    },
    computed: {
      isEmptyPassword() {
        return !this.payload.password || this.payload.password.length < 1
      },
      passwordStrengthColor() {
        const { id } = passwordStrength(this.payload.password)
        return this.passwordStrengthColors[id] ?? 'danger'
      },
      passwordStrengthValue() {
        if (this.isEmptyPassword) {
          return 100
        }

        const { id } = passwordStrength(this.payload.password)
        return (25 + (25 * id))
      },
      passwordStrengthLabel() {
        if (this.isEmptyPassword) {
          return 'Informe uma senha segura'
        }

        const { id } = passwordStrength(this.payload.password)
        const levels = ['muito fraca', 'fraca', 'média', 'segura']
        const suffix = levels[id] ?? 'muito fraca'
        return `Senha ${suffix}`
      }
    }
}
</script>

<template>
    <div class="auth-page">
        <div class="container-fluid p-0">
            <div class="row g-0 align-items-center">
                <div class="col-xxl-6 mx-auto col-lg-5 col-md-6">
                    <div class="row justify-content-center g-0">
                        <div class="col-xl-9">
                            <div class="p-4">
                                <div class="card mb-0">
                                    <div class="card-body">
                                        <div class="auth-full-page-content rounded d-flex p-3 my-2">
                                            <div class="w-100">
                                                <div class="d-flex flex-column h-100">
                                                    <div class="mb-4 mb-md-5">
                                                        <router-link to="/" class="d-block auth-logo">
                                                            <img src="@/assets/images/logo-dark.png" alt="" height="22" class="auth-logo-dark me-start">
                                                            <img src="@/assets/images/logo-light.png" alt="" height="22" class="auth-logo-light me-start">
                                                        </router-link>
                                                    </div>
                                                    <div class="auth-content my-auto">
                                                        <div class="text-center">
                                                            <h5 class="mb-0">Criar conta</h5>
                                                            <p class="text-muted mt-2">Torne-se um vendedor agora mesmo!</p>
                                                        </div>
                                                        <form class="mt-4 pt-2" @submit.prevent="register()">
                                                            <div class="form-floating form-floating-custom mb-4">
                                                                <input 
                                                                    v-model="v$.payload.name.$model"
                                                                    type="text"
                                                                    class="form-control" 
                                                                    :class="{ 'form-group--error': v$.payload.name.$error }"
                                                                    id="input-name" 
                                                                    placeholder="Enter User Name"
                                                                 >
                                                                 <b-form-invalid-feedback id="name-feedback" v-if="v$.payload.name.$error">
                                                                     Nome de usuário deve ter no mínimo 3 caracteres
                                                                 </b-form-invalid-feedback>
                                                            
                                                                <label for="input-username">Nome completo</label>
                                                                <div class="form-floating-icon">
                                                                    <eva-icon name="people-outline"></eva-icon>
                                                                </div>
                                                            </div>
                                                            <div class="form-floating form-floating-custom mb-4">
                                                                <input
                                                                    type="email"
                                                                    class="form-control"
                                                                    id="input-email"
                                                                    placeholder="Enter Email"                                                                     
                                                                    v-model="v$.payload.email.$model"
                                                                 >
                                                                <div class="invalid-feedback" v-if="v$.payload.email.$error">
                                                                    Digite um email válido
                                                                </div> 
                                                                <label for="input-email">Email</label>
                                                                <div class="form-floating-icon">
                                                                    <eva-icon name="email-outline"></eva-icon>
                                                                </div>
                                                            </div>
                

                                                            <div class="form-floating form-floating-custom mb-4">
                                                                <input 
                                                                    type="text"
                                                                    class="form-control" 
                                                                    id="input-whatsapp"
                                                                    v-mask="'(##) # ####-####'"
                                                                    placeholder="Por favor, digite seu whatsapp" 
                                                                    v-model="v$.payload.whatsapp.$model"
                                                                 >
                                                                <div class="invalid-feedback" v-if="v$.payload.whatsapp.$error">
                                                                    Por favor, digite seu whatsapp
                                                                </div> 
                                                                <label for="input-username">Whatsapp</label>
                                                                <div class="form-floating-icon">
                                                                    <eva-icon name="phone-outline"></eva-icon>
                                                                </div>
                                                            </div>
                
                                                            <div class="form-floating form-floating-custom mb-4">
                                                                <input 
                                                                  :type="showPassword ? 'text' : 'password'"
                                                                  class="form-control" 
                                                                  id="input-password" 
                                                                  placeholder="Enter Password"
                                                                  v-model="v$.payload.password.$model"
                                                                >
                                                                <label for="input-password">Senha</label>
                                                                <div class="form-floating-icon">
                                                                  <eva-icon
                                                                    :name="showPassword ? 'eye' : 'eye-off'"
                                                                    @click="showPassword = !showPassword"
                                                                  ></eva-icon>
                                                                </div>
                                                                <b-progress
                                                                  :max="100"
                                                                  :striped="false"
                                                                  class="mt-1"
                                                                  style="height: .75rem;"
                                                                >
                                                                  <b-progress-bar
                                                                    :value="passwordStrengthValue"
                                                                    :variant="passwordStrengthColor"
                                                                  >
                                                                    {{ passwordStrengthLabel }}
                                                                  </b-progress-bar>
                                                                </b-progress>
                                                            </div>
                    
                                                            <div class="mb-5">
                                                                <b-form-checkbox
                                                                  id="checkbox-1"
                                                                  v-model="v$.payload.term.$model"
                                                                  name="checkbox-1"
                                                                >
                                                                  Ao se registrar, você aceita nossos
                                                                  <a :href="linkTerms" target="_blank">
                                                                    Termos de Uso
                                                                  </a>
                                                                  e a nossa
                                                                  <a :href="linkPrivacy" target="_blank">
                                                                    Política de Privacidade.
                                                                  </a>
                                                                </b-form-checkbox>
                                                                <div class="invalid-feedback text-center" v-if="!v$.payload.term.$model">
                                                                  É necessário aceitar os termos para concluir o registro.
                                                                </div>
                                                            </div>
                                                            <div class="mb-3">

                                                                <button  
                                                                    class="btn btn-primary w-100 btn-lg waves-effect waves-light" 
                                                                    type="submit"
                                                                    :disabled="v$.payload.$invalid || !v$.payload.term.$model"
                                                                >
                                                                <b-spinner small v-if="isFormSubmitted"></b-spinner>
                                                                Registar
                                                            </button>
                                                            </div>
                                                        </form>                                                        
                    
                                                        <div class="mt-4 pt-3 text-center">
                                                            <p class="text-muted mb-0">Já possui conta? <router-link to="/auth/login" class="text-primary fw-semibold"> Login </router-link> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end auth full page content -->
                </div>
                <!-- end col -->                
            </div>
            <!-- end row -->
        </div>
        <!-- end container fluid -->
    </div>
</template>

<style scoped>
.invalid-feedback {
    display: block;
    position: absolute;
}
</style>